import { Controller } from "stimulus";
import cart from "util/cart.js";


export default class extends Controller {
  static targets = ["cartItemsCount", "icon"];

  connect () {
    this.updateCartItemsCount();
  }

  handleItemQuoteResponse(item, response) {
    if (response.status === "error") {
      cart.remove(item.params.type, item.params.unit_id);
    }
  }

  async goToCart() {
    try {
      const itemsWithQuotes = await cart.itemsWithQuotes();
      itemsWithQuotes.forEach(item => {
        this.handleItemQuoteResponse(item, item.quote)
      })
      window.location.href = this.iconTarget.dataset.link;
      
    } catch (error) {
      console.error('Error checking availability of cart items:', error);
    }
  }


  updateCartItemsCount() {
    const cartItemsCount = cart.items().length;

    if (cartItemsCount > 0) {
      this.cartItemsCountTarget.classList.remove("hidden");
      this.cartItemsCountTarget.textContent = cartItemsCount;
    } else {
      this.cartItemsCountTarget.classList.add("hidden");
      this.cartItemsCountTarget.textContent = "";
    }
  }

}
