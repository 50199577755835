import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import cart from "util/cart.js";
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = [ "slider", "currentSlide", "buttonSelectUnit"]

  connect() {
    useDispatch(this, {debug: true});
    this.unitId = this.data.get("unit-id");
    this.unitTypeId = this.data.get("unit-type-id");
  }

  onModalOpened() {
    this.glide = new Glide(this.sliderTarget, { 
      type: 'carousel', 
      perView: 1, 
      gap: 20,
      swipeThreshold: 5,
      dragThreshold: 5 
    }).mount();
    this.updateSlideNumbers();
    this.glide.on(["run"], () => {
      this.updateSlideNumbers();
    });
  }

  updateSlideNumbers() {
    const currentSlideNumber = this.glide.index + 1;
    if (this.currentSlideTarget) {
      this.currentSlideTarget.textContent = this.pad(currentSlideNumber);
    }
  }

  pad(number) {
    return number.toString().padStart(2, '0');
  }

  disconnect() {
    this.glide.destroy()
  }

  updateButtonState(e) {
    if (e.detail.buttonState === "updateCart") {
      this.setUpdateCartButtonState();
    } else if (e.detail.buttonState === "inCart") {
      this.setSelectedCartButtonState()
    } else {
      this.setUnselectedCartButtonState();
    }
  }

  checkItemPresenceInCart() {
    const isItemPresentInCart = cart.item("unit", this.unitId);

    if (isItemPresentInCart) {
      this.setSelectedCartButtonState();
    } else {
      this.setUnselectedCartButtonState();
    }
  }

  setUpdateCartButtonState() {
    this.buttonSelectUnitTarget.classList.add("selected");
    this.buttonSelectUnitTarget.textContent = "Update";
  }

  setSelectedCartButtonState() {
    this.buttonSelectUnitTarget.classList.add("selected");
    this.buttonSelectUnitTarget.textContent = "In Cart";
  }

  setUnselectedCartButtonState() {
    this.buttonSelectUnitTarget.classList.remove("selected");
    this.buttonSelectUnitTarget.textContent = "Add to Cart";
  }

  saveTotal(e) {
    if(e.detail.total) {
      this.total = e.detail.total;
    }
  }

  updateCartInfo(e) {
    this.bookingItemParams = e.detail.bookingItemParams;
  }

  // handleUpdateInCart() {
  //   const {unitId} = this;
  //   cart.update(unitId, this.bookingItemParams());
  //   this.setSelectedCartButtonState();
  //   window.dispatchEvent(new CustomEvent(`cartUpdated-${unitId}`));
  //   window.dispatchEvent(new CustomEvent(`cartUpdated`));
  // }

  selectUnitHandleClick(event) {
    event.stopPropagation();

    if (this.buttonSelectUnitTarget.textContent.trim() === "Add to Cart") {
      const isItemPresentInCart = cart.item("unit", this.unitId);
      if (!isItemPresentInCart) {
        this.dispatch(`selectedUnit-${this.unitId}`, { unitId: this.unitId, total: this.total });
      }
    } else  if (this.buttonSelectUnitTarget.textContent.trim() === "Update"){
      // const bookingItemParams = cart.item(this.unitId);
      cart.update("unit", this.unitId, this.bookingItemParams);
      this.dispatch(`updateInCart-${this.unitId}`, {bookingItemParams: this.bookingItemParams});
      this.setSelectedCartButtonState();

    }
  }
}
