import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["list", "map", "listToggle", "mapToggle", "view"];

  handleClickList() {
    if (this.listToggleTarget.classList.contains('active')) return
    if (this.mapToggleTarget.classList.contains('active')) {
      this.mapToggleTarget.classList.remove('active');
      this.listToggleTarget.classList.add('active');
      this.mapTarget.classList.remove('show');
      this.listTarget.classList.add('show');
    }
  }

  handleClickMap() {
    if (this.mapToggleTarget.classList.contains('active')) return
    if (this.listToggleTarget.classList.contains('active')) {
      this.listToggleTarget.classList.remove('active');
      this.mapToggleTarget.classList.add('active');
      this.listTarget.classList.remove('show');
      this.mapTarget.classList.add('show');
    }
  }

}
