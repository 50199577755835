// import Flatpickr from "stimulus-flatpickr";
import { Controller } from "stimulus";
import { submitForm } from "init/ajax";

export default class extends Controller {
  static targets = ["form", "submit", "token", "openCalendar"];

  initialize() {
    this.config = {
      mode: "range",
      minDate: "today",
      clickOpens: false,
      showMonths: 2,
    };
  }

  connect() {
    this.container = this.element;
    this.originalForm = this.formTarget.innerHTML;
    this.travelDatesLabel = this.container.querySelector(".travel-dates-label");
    if(this.travelDatesLabel)
      this.originalCalendarPlaceholder = this.travelDatesLabel.textContent;
    this.groupTypeDropdown = this.container.querySelector(".group-type-dropdown");
    if(this.groupTypeDropdown){
      this.originalGroupTypePlaceholder = this.container.querySelector(".group-type-dropdown .selected").textContent;
      this.groupTypeDropdown.querySelector('.select-items-wrapper ul li.selected').remove();

      this.groupTypeDropdown.querySelector('svg').addEventListener('click', (e) => {
        e.stopPropagation();
        this.groupTypeCustomSelect = this.groupTypeDropdown.querySelector('.custom-select');
        this.optionsListWrapperTarget = this.groupTypeDropdown.querySelector('.custom-select .select-items-wrapper');

        this.groupTypeCustomSelect.classList.toggle("open");
        this.optionsListWrapperTarget.classList.toggle("select-hide");
        this.groupTypeCustomSelect.dispatchEvent(new CustomEvent("toggle-select"));
      })
    }
    this.inFlight = false;
    this.overlay = this.container.querySelector(".calendar-overlay");
    this.contactForm = document.querySelector(".contact-form-group-travel");
    this.closeBtn = this.overlay.querySelector(".close-calendar");
    // this.openCalendarTarget.addEventListener("click", this.toggleCalendar.bind(this));
    if (this.closeBtn) {
      this.closeBtn.addEventListener("click", this.close.bind(this));
    }
    this.errorMessage = 'Please choose one of provided options';

    //document.addEventListener('click', (e) => {
    //  if (!this.contactForm.contains(e.target) && this.overlay.classList.contains('open')) {
    //    this.overlay.classList.remove('open');
    //  }
    //});

    this.container.querySelectorAll('.input-field.required .radio-block .input-field input').forEach(radio => {
      radio.addEventListener('click', (e) => {
            let parent = radio.closest('.input-field.required');
            if (parent) {
              parent.classList.remove("error")
            }
          }
      )
    });

    this.container.querySelectorAll(".input-field.required .custom-select .select-items li").forEach(option => {
      option.addEventListener('click', (e) => {
            let parent = option.closest('.custom-select');
            if (parent) {
              parent.classList.remove("error")
            }
          }
      )
    });
  }

  close(e) {
    document.body.style.overflowY = "auto";
    this.overlay.classList.remove("open");
  }

  toggleCalendar(e) {
    e.stopPropagation();
    if (this.overlay.classList.contains("open")) {
      document.body.style.overflowY = "auto";
      this.overlay.classList.remove("open");
    } else {
      document.body.style.overflow = "hidden";
      this.overlay.classList.add("open");
    }
  }

  validate() {
    let valid = true;
    const requiredFields = this.formTarget.querySelectorAll("[required]");
    const customSelectRequired = this.formTarget.querySelector(".input-field.required .custom-select");
    const radioRequired = this.formTarget.querySelectorAll(".radio-input.required");

    requiredFields.forEach(field => {
      const errorMsg = field.parentNode.querySelector(".error-msg");
      if (field.checkValidity()) {
        field.parentNode.classList.remove("error");
        if (errorMsg) errorMsg.parentNode.removeChild(errorMsg);
      } else {
        const parent = field.parentNode;
        const child = errorMsg || document.createElement("div");
        const msg = field.validationMessage;
        child.innerHTML = `<span class="error-msg">${msg}</span>`;
        parent.classList.add("error");
        parent.appendChild(child);
        valid = false;
      }
    });


    const radioValidationArray = [];
    radioRequired.forEach(((field, index) => {
      const radioGroups = field.querySelectorAll('.input-field input');
      const radioGroupValidationArray = [];

      radioGroups.forEach((radio, index) => {
        radioGroupValidationArray[index] = !!radio.checked
      });

      if (radioGroupValidationArray.indexOf(true) === -1) {
        let parent = field.closest('.input-field.required');
        if (parent) {
          const child =document.createElement("div");
          const msg = this.errorMessage;
          child.innerHTML = `<span class="error-msg">${msg}</span>`;
          child.classList.add("error-msg-block");
          parent.classList.add("error");
          parent.appendChild(child);
        }
      }

      radioValidationArray[index] = radioGroupValidationArray;
    }));

    const radioInvalid = radioValidationArray.some(subArray => subArray.every(option => option === false));
    if (radioInvalid) {
      valid = false
    }


    const validOptions = [];
    if(customSelectRequired) {
      customSelectRequired.querySelectorAll("select option").forEach(option => {
        if (option.value) {
          validOptions.push(option.value);
        }
      });
      const currentOption = customSelectRequired.querySelector(".select-selected").innerText;

      if (validOptions.indexOf(currentOption) === -1) {
        valid = false;

        const child =document.createElement("div");
        const msg = this.errorMessage;
        child.classList.add("error-msg-block");
        child.innerHTML = `<span class="error-msg">${msg}</span>`;
        customSelectRequired.classList.add("error");
        customSelectRequired.appendChild(child);
      }
    }
    return valid;
  }

  scrollToFirstError() {
    const el = this.formTarget.querySelector(".error");
    const elTopDistance = window.pageYOffset + el.getBoundingClientRect().top;
    const headerHeight = document
      .querySelector("body > .header")
      .getBoundingClientRect().height;
    const headerScrollGap = 32;
    window.scrollTo({
      top: elTopDistance - headerHeight - headerScrollGap,
      behavior: "smooth"
    });
  }

  async submit(e) {
    e.preventDefault();
    e.returnValue = false;

    if (this.inFlight) {
      return;
    }
    const token = await window.grecaptcha.enterprise.execute('6LcT6F4iAAAAAK_SIlVjZSBjS88mT6bQ70jiOYS5', {action: 'CONTACT'});
    this.tokenTarget.value = token;

    const valid = this.validate();
    if (!valid) {
      this.scrollToFirstError();

    } else {
      this.disableSubmit();
      if(window._learnq) {
        const eventName = this.formTarget.event_name.value;
        _learnq.push(['identify', {
          '$email' : this.formTarget.form_fields_email.value}]);
        setTimeout(() => {
          _learnq.push(['track', eventName, {}]);
        }, 100);

      }

      const group_type_options = this.formTarget.querySelector('[name="form[fields][group_type_options]"]');
      if (group_type_options) {
        const selected_group_type_option = this.formTarget.querySelector('.custom-select .select-selected')
        if (selected_group_type_option) {
          this.formTarget.form_fields_group_type_options.value = selected_group_type_option.innerText
        }
      }

      submitForm(this.formTarget).then(response => {
        if (response.success === "true") {
          this.element.classList.add("success");
          this.inFlight = false;
          // keep this in case it will be necessary to reload form
          // setTimeout(() => {
          //   this.element.classList.remove("success");
          // }, 5000);

          // saves event
          window.dataLayer.push({
            "event": "groupFormSubmission",
            "form_submission_identifier": this.formTarget.form_submission_identifier
          });

          window.dataLayer.push({
            event: "GTMevent",
            event_name: "group_form_completed",
            event_data: {
              "form_submission_identifier": this.formTarget.form_submission_identifier
            }
          });
        } else {
          this.formTarget.innerHTML = `
            <strong>Sorry, there was an error with your request</strong>
          `;
        }
      });
      // .then(() => {
      //   this.clearForm();
      //   this.enableSubmit();
      // });
    }
  }

  // clearForm() {
  //   this.resetFormCalendar()
  //   this.formTarget.reset();
  // }

  resetFormCalendar() {
    this.container.querySelector(".travel-dates-label").textContent = this.originalCalendarPlaceholder;
    this.container.querySelector(".group-type-dropdown .select-selected").textContent = this.originalGroupTypePlaceholder;
    this.container.querySelectorAll(".calendar-hero-light .inRange").forEach((day) => { day.classList.remove("inRange")});
    this.container.querySelectorAll(".calendar-hero-light .selected").forEach((day) => {
      day.classList.remove("startRange");
      day.classList.remove("endRange");
      day.classList.remove("selected")
    });
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.inFlight = true;
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.inFlight = false;
  }
}
