export default async function unitQuote(params = {}) {
    const response = await fetch("/api/quote.json", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
    })

    return await response.json()
}

export async function canReserve(params = {}) {
    const response = await unitQuote(params)
    return !(response.status && response.status === "error")
}
