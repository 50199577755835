import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["header"];

  unfixHeader() {
    if (!this.headerTarget.classList.contains('fixed')) return;

    this.headerTarget.classList.remove('fixed');
  }

  fixHeader() {
    if (this.headerTarget.classList.contains('fixed')) return;

    this.headerTarget.classList.add('fixed');
  }
}
