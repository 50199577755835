import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import { useDispatch } from "stimulus-use";
import { getCookie } from "../../init/cookie";


export default class extends Controller {
  static targets = ["glide", "bookBtn"];

  connect() {
    useDispatch(this);
    this.setupGlideJS();
    this.setDatesVariables();
    this.updateBookButton();
  }

  setupGlideJS() {
    const slides = this.glideTarget.querySelector(".glide__slides");
  
    if (slides.children.length === 0) {
      return;
    }
  
    this.glide = new Glide(this.glideTarget, {
      type: "slider",
      perView: 1,
      animationDuration: 300,
      gap: 20,
      swipeThreshold: 5,
      dragThreshold: 5
    }).mount();
  }

  setDatesVariables() {
    // get dates from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.checkin = urlParams.get("checkin");
    this.checkout = urlParams.get("checkout");

    if (this.checkin && this.checkout) return;

    // get dates from cookies if url parameters doesn't contain dates
    const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
    this.checkin = cookies.checkin;
    this.checkout = cookies.checkout;
    
  }

  updateBookButton() {
    if (!this.checkin  || !this.checkout) {
      this.bookBtnTarget.classList.add("noDates")
    } else {
      this.bookBtnTarget.classList.remove("noDates");
    }
  }

  goToProperty() {
    const bookUrl = this.bookBtnTarget.getAttribute("data-url");
    if (this.checkin && this.checkout && bookUrl) {
      window.location.href = `${bookUrl}?checkin=${this.checkin}&checkout=${this.checkout}`;
    } else if (bookUrl) {
      window.location.href = `${bookUrl}`;
    }
      
  }

  handleBookNowBtnClick() {
    if (!this.checkin  || !this.checkout) {
      this.dispatch("openCalendar");
    } else {
      this.goToProperty();
    }
  }
  
}
