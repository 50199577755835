import { Controller } from "stimulus";
import { useThrottle, useDispatch, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ["mainWrapper", "optionsBlock"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect() {
    useThrottle(this, { wait: 100 });
    useDebounce(this, { wait: 100 });
    useDispatch(this);

    this.scrollDelta = 60;
    this.prevScrollPosition = 0;
    this.isSearchbarMiniMode = false;
    this.absoluteSearchbarPosition = this.getAbsoluteSearchbarPosition();
    this.subHeader = document.querySelector(".sub-header");
    if (this.subHeader) {
      this.dispatchHeaderEvents("unfixHeader");
    }
  }

  handleScroll() {
    this.adjustSearchbarAppearance();
    if (this.subHeader) {
      this.adjustHeadersPosition();
      this.adjustSubHeaderAndSearchbarOnScrollUp();
    }

  }

  handleScrollEnd() {
    this.adjustSearchbarAppearance();
  }

  adjustSearchbarAppearance() {
    const isDesktop = window.innerWidth >= 1200;
  
    if (isDesktop) {
      // Check if the scroll position is greater than the top of optionsBlockTarget
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - this.optionsBlockTarget.offsetHeight) {
        this.handleSearchbarMiniMode();
      } else {
        this.handleSearchbarNormalMode();
      }
    } else {
      // For mobile, apply different logic
      if (window.scrollY + 64 >= this.absoluteSearchbarPosition - 250 ) {
        this.handleSearchbarMiniMode();
      } else {
        this.handleSearchbarNormalMode();
      }
    }
  }

  adjustSubHeaderAndSearchbarOnScrollUp() {
    if (window.scrollY < this.prevScrollPositionForSubHeader) {
      this.dispatchHeaderEvents("fixSubHeader");
      this.optionsBlockTarget.classList.add('under-header');
    } else {
      this.optionsBlockTarget.classList.remove('under-header');
    }
    this.prevScrollPositionForSubHeader = window.scrollY;
  }

  adjustHeadersPosition() {
    const isDesktop = window.innerWidth >= 1200;
    if (isDesktop) {
      if (window.scrollY + 70 >= this.absoluteSearchbarPosition - this.optionsBlockTarget.offsetHeight) {
        this.dispatchHeaderEvents("unfixSubHeader");

      } else {
        this.dispatchHeaderEvents("fixSubHeader");
      }
    } else {
      if (window.scrollY + 64 >=  this.absoluteSearchbarPosition - 250) {
        this.dispatchHeaderEvents("unfixSubHeader");
      } else {
        this.dispatchHeaderEvents("fixSubHeader");
      }
    }
  }

  getAbsoluteSearchbarPosition() {
    let absoluteY = 0;
    let currentElement = this.optionsBlockTarget;

    while (currentElement.offsetParent !== null) {
      absoluteY += currentElement.offsetTop;
      currentElement = currentElement.offsetParent;
    }

    return absoluteY + this.optionsBlockTarget.offsetHeight;
  }

  getWrapperHeight() {
    return this.mainWrapperTarget.getBoundingClientRect().height;
  }

  setSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = true;
  }

  resetSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = false;
    // memorize window sroll position to switch searchbar to mini mode on scroll
    this.prevScrollPosition = window.scrollY;
  }

  handleSearchbarMiniMode() {
    if (this.isSearchbarMiniMode) return;
      this.optionsBlockTarget.classList.add('fixed');
      this.dispatch('searchbarMini');
  }

  handleSearchbarNormalMode() {
    if (!this.isSearchbarMiniMode) return;

    this.optionsBlockTarget.classList.remove('fixed');
    this.dispatch('searchbarNormal');
  }

  dispatchHeaderEvents(event) {
    window.dispatchEvent(new CustomEvent(event));
  }
}
