import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filtersBlock"];

  connect() {
    const urlObject = new URL(window.location.href);
    if (urlObject.searchParams.get('display') === 'unit_type') {
      this.filtersBlockTarget.classList.add("hide");
    } else if (urlObject.searchParams.get('display') === 'unit') {
      this.filtersBlockTarget.classList.remove("hide");
    } 
  }

}
