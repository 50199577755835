import { DateTime } from "luxon";
import { deleteCookie, setCookie, getCookie } from "init/cookie";
import unitQuote from "util/quote";

function getInitialCartObject() {
  return {
    last_change: DateTime.now(),
    items: []
  };
}

function resetCart() {
  localStorage.removeItem("cart");
  deleteCookie("cart");
  return getInitialCartObject();
}

function saveCart(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
  setCookie("cart", JSON.stringify(cart), 3);
}

function getCart() {
  const cartDataObject = localStorage.getItem("cart");

  if (cartDataObject === null) {
    return getInitialCartObject();
  }

  const cart = JSON.parse(cartDataObject);
  const lastChange = DateTime.fromJSDate(cart.last_change);
  if (lastChange.diffNow("hours") >= 4) {
    return resetCart();
  }

  return cart;
}

function syncCartWithCookies() {
  const cart = getCart();
  const cookieCart = JSON.parse(getCookie("cart") || "{}");

  if (!cookieCart.items) {
    resetCart();
    return;
  }

  const cookieItemsSet = new Set(cookieCart.items.map(item => `${item.unit_id}-${item.type}`));

  cart.items = cart.items.filter(item =>
    cookieItemsSet.has(`${item.unit_id}-${item.type}`)
  );

  saveCart(cart);
}

function getCartItems() {
  syncCartWithCookies();
  const cart = getCart();
  return cart.items;
}

async function getCartItemsWithQuotes() {
  const promiseList = getCartItems().map(async item => ({
    params: item,
    quote: await unitQuote(item)
  }));

  return Promise.all(promiseList);
}

function addToCart(params = {}) {
  const cart = getCart();
  cart.items.push(params);
  saveCart(cart);
}

function removeFromCart(unitType, unitId) {
  const cart = getCart();
  cart.items = cart.items.filter(
    item => (item.unit_id !== unitId || (item.unit_id === unitId && item.type !== unitType))
  );
  saveCart(cart);
}

function getCartItem(unitType, unitId) {
  const cart = getCart();
  return cart.items.find(
    item => item.unit_id === unitId && item.type === unitType
  );
}

async function getCartItemWithQuote(unitType, unitId) {
  const item = getCartItem(unitType, unitId);
  return {
    params: item,
    quote: await unitQuote(item)
  };
}

function updateCartItem(unitType, unitId, params = {}) {
  const cart = getCart();
  cart.items = cart.items.map(item =>
    item.unit_id === unitId && item.type === unitType ? params : item
  );
  saveCart(cart);
}

const cart = {
  items: getCartItems,
  itemsWithQuotes: getCartItemsWithQuotes,
  add: addToCart,
  remove: removeFromCart,
  clear: resetCart,
  item: getCartItem,
  itemWithQuote: getCartItemWithQuote,
  update: updateCartItem
};

window.vltcart = cart;
export default cart;
