import { Controller } from "stimulus";
import { useDispatch } from 'stimulus-use';

export default class extends Controller {

  static targets = ["list", "map", "listToggle", "mapToggle", "view"];

  connect() {
    useDispatch(this, {debug: true});
  }
  
  handleClickList() {
    this.dispatch('toggleClicked', { toogle: "list" });
    if (this.listToggleTarget.classList.contains('active')) return
    if (this.mapToggleTarget.classList.contains('active')) {
      this.mapToggleTarget.classList.remove('active');
      this.listToggleTarget.classList.add('active');
    }
  }
  
  handleClickMap() {
    this.dispatch('toggleClicked', { toogle: "map" })
    if (this.mapToggleTarget.classList.contains('active')) return
    if (this.listToggleTarget.classList.contains('active')) {
      this.listToggleTarget.classList.remove('active');
      this.mapToggleTarget.classList.add('active');
    }
  }
}
